import {
    SetUserInfoAction,
    SetUserValidationResultAction,
    SetTokenInfoAction,
} from "./types"
import * as Constants from './constants'
import { TokenInfo, UserInfo } from "../../../types"

export const setUserInfoAction = ({ userInfo }: { userInfo: UserInfo }): SetUserInfoAction => {
    return {
        type: Constants.SET_USER_INFO,
        payload: { userInfo }
    }
}

export const setUserValidationResultAction = ({ hasAccess }: { hasAccess: boolean }): SetUserValidationResultAction => {
    return {
        type: Constants.SET_USER_VALIDATION_RESULT,
        payload: { hasAccess }
    }
}

export const setTokenInfoAction = ({ tokenInfo }: { tokenInfo: TokenInfo }): SetTokenInfoAction => {
    return {
        type: Constants.SET_TOKEN_INFO,
        payload: { tokenInfo }
    }
}