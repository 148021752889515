import { call, delay, put, select } from 'redux-saga/effects';
import { AuthenticationResult } from '@azure/msal-browser';
import { msalInstance } from '../../../authentication/msalConfiguration';
import { loginRequest } from '../../../authentication/authConfig';
import { TokenInfo, UserInfo } from '../../types';
import { setUserInfoAction, userVerificationFinishedAction, setTokenInfoAction } from '../../redux/actions';
import { loadCurrentUserSettingsViaSagaAction, validateCurrentUserViaSagaAction } from '../actions';
import { consoleLoggerServiceFactory } from '../../../services';
import { userInfoTokenSelector } from '../../selectors';

const login = async (): Promise<AuthenticationResult | null> => {
    const response = await msalInstance.ssoSilent(loginRequest);

    return response;
};

const renew = async (): Promise<AuthenticationResult | null> => {
    const response = await msalInstance.acquireTokenSilent(loginRequest);
    msalInstance.setActiveAccount(response.account);

    return response;
}

export function* userLoginSaga() {
    consoleLoggerServiceFactory().logMessage("userLoginSaga")
    try {
        const response: AuthenticationResult | null = yield call(login);
        if (response) {
            let userInfo: UserInfo = {
                fullName: response.account?.name!,
                email: response.account?.username!,
                role: response.account?.idTokenClaims?.roles?.[0] ?? "no role",
                hasAccess: true,  //will be checked a bit later
            }
            let tokenInfo: TokenInfo = {
                accessToken: response.accessToken,
                expiresOn: response.expiresOn!,
            }

            yield put(setUserInfoAction({ userInfo }))
            yield put(setTokenInfoAction({ tokenInfo }))

            //check if the user is known in database
            yield put(validateCurrentUserViaSagaAction())
            yield put(loadCurrentUserSettingsViaSagaAction())
        }
    } catch (error) {
        consoleLoggerServiceFactory().logError("Error in ssoSilent", error)
        if (((error as Error).name = 'InteractionRequiredAuthError')) {
            return msalInstance.loginRedirect(loginRequest).catch((e: unknown) => {
                consoleLoggerServiceFactory().logError("Error in loginRedirect", e)
            })
        }
    }
}

export function* tokenRenewalSaga() {
    consoleLoggerServiceFactory().logMessage("tokenRenewalSaga")

    while (true) {
        const tokenInfo: TokenInfo = yield select(userInfoTokenSelector);
        const expirationTime = tokenInfo?.expiresOn ? new Date(tokenInfo.expiresOn).getTime() : 0;
        const currentTime = Date.now();
        const bufferTime = 120_000; // 2 minutes buffer before expiration

        if (currentTime >= expirationTime - bufferTime) {
            try {
                msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
                const account = msalInstance.getActiveAccount();
                if (account) {
                    const response: AuthenticationResult | null = yield call(renew);
                    if (response) {
                        if (tokenInfo.accessToken === "") {
                            //This handles situation on page refresh
                            //The user is logged in, but the application state was re-set, so we need to set it again.
                            let userInfo: UserInfo = {
                                fullName: response.account?.name!,
                                email: response.account?.username!,
                                role: response.account?.idTokenClaims?.roles?.[0] ?? "no role",
                                hasAccess: true,
                            }
                            yield put(setUserInfoAction({ userInfo }))
                            yield put(userVerificationFinishedAction(true));
                        }
                        //This is ordinary token refresh.
                        //We do not want to update all the userInfo, since it will trigger layout refresh.
                        let newTokenInfo: TokenInfo = {
                            accessToken: response.accessToken,
                            expiresOn: response.expiresOn!,
                        }

                        yield put(setTokenInfoAction({ tokenInfo: newTokenInfo }))
                    }
                }
            } catch (error) {
                // Handle token renewal failure (e.g., redirect to login or show error)
                consoleLoggerServiceFactory().logError("acquireTokenSilent failed", error)

                msalInstance.loginRedirect(loginRequest).catch((e: unknown) => {
                    consoleLoggerServiceFactory().logError("Error in loginRedirect", e)
                })
            }
        }

        yield delay(60_000); // Check every minute
    }
}
