import { Button, SelectChangeEvent, Stack } from "@mui/material"
import { SelectWithCheckboxes, DateSelect, FormSelectData, FormSelect } from "../../../../components/formElements"
import { useDispatch, useSelector } from "react-redux"
import { FormEventHandler, useEffect, useState } from "react"
import { loadAllProjectDataViaSagaAction, loadAllUsersViaSagaAction, loadKindDataViaSagaAction } from "../../../../state/sagas/actions"
import { HATState } from "../../../../state"
import { REPORTS_LABELS } from './translations'
import { useAppTranslationByLocation } from "../../../../translations"
import { consoleLoggerServiceFactory, reportService } from "../../../../services"
import { userInfoTokenSelector } from "../../../../state/selectors"
import { formatDateFullTimestamp, downloadFile } from "../../../../utils"
import { reportDefinition } from "./reportDefinition"

export const Reports = () => {
    const dispatch = useDispatch();
    const { t } = useAppTranslationByLocation();

    const [dateFrom, setDateFrom] = useState<Date>(new Date());
    const [dateTo, setDateTo] = useState<Date>(new Date());
    const [selectedReport, setSelectedReport] = useState<string>('');
    const [selectedProjects, setSelectedProjects] = useState<number[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
    const [selectedKinds, setSelectedKinds] = useState<number[]>([]);
    const [selectedWbs, setSelectedWbs] = useState<number[]>([]);

    const projects = useSelector((state: HATState) => state.adminData.allProjects);
    const users = useSelector((state: HATState) => state.adminData.allUsers);
    const kinds = useSelector((state: HATState) => state.data.kinds);
    const { accessToken } = useSelector(userInfoTokenSelector);

    useEffect(() => {
        dispatch(loadAllProjectDataViaSagaAction());
        dispatch(loadAllUsersViaSagaAction());
        dispatch(loadKindDataViaSagaAction());
    }, [dispatch]);

    useEffect(() => {
        //update selected wbs list - remove wbs from unselected projects
        const wbsToRemove = selectedWbs.filter(w => !(selectedProjects.some(sp => projects.find(p => p.id === sp)?.WBS.some(pw => pw.id === w))))
        if (wbsToRemove.length > 0) {
            const filteredWbs = selectedWbs.filter(w => !wbsToRemove.includes(w))
            setSelectedWbs(filteredWbs)
        }
    }, [selectedProjects, projects, selectedWbs])

    const handleDateFromChange = (selectedDate: Date) => {
        setDateFrom(selectedDate)
    }

    const handleDateToChange = (selectedDate: Date) => {
        setDateTo(selectedDate)
    }

    const handleReportChange = (event: SelectChangeEvent) => {
        setSelectedReport(event.target.value);
    }

    const handleProjectChange = (selectedValues: number[]) => {
        setSelectedProjects(selectedValues)
    }

    const handleUserChange = (selectedValues: number[]) => {
        setSelectedUsers(selectedValues)
    }

    const handleKindChange = (selectedValues: number[]) => {
        setSelectedKinds(selectedValues)
    }

    const handleWbsChange = (selectedValues: number[]) => {
        setSelectedWbs(selectedValues)
    }

    const handleSubmit: FormEventHandler = (e) => {
        e.preventDefault();

        downloadReport();
    }

    const downloadReport = () => {
        switch (parseInt(selectedReport)) {
            case 1:
                reportService(accessToken)
                    .getDetailReport(dateFrom, dateTo, selectedProjects, selectedWbs, selectedKinds)
                    .then(({ data, contentType }) => downloadFile(data, `Details_report_${formatDateFullTimestamp(new Date())}.xlsx`, contentType));
                break;
            case 2:
                reportService(accessToken)
                    .getTimeReport(dateFrom, dateTo, selectedProjects, selectedWbs, selectedKinds)
                    .then(({ data, contentType }) => downloadFile(data, `Time_report_${formatDateFullTimestamp(new Date())}.xlsx`, contentType));
                break;
            default: consoleLoggerServiceFactory().logWarning("report not recognized")
        }
    };
    let currentReportDefinition = reportDefinition.find(r => r.key === parseInt(selectedReport))

    return (
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Stack spacing={2} padding={1}>
                <FormSelect id="reportSelect"
                    label={t(REPORTS_LABELS.SELECT_REPORT)}
                    data={reportDefinition}
                    value={selectedReport}
                    onChange={handleReportChange} />
                <Stack spacing={2} direction="row">
                    <DateSelect label={t(REPORTS_LABELS.DATE_FROM)}
                        selectedDate={dateFrom}
                        onDateChanged={handleDateFromChange} />
                    <DateSelect label={t(REPORTS_LABELS.DATE_TO)}
                        selectedDate={dateTo}
                        onDateChanged={handleDateToChange} />
                </Stack>
                <SelectWithCheckboxes id="projectSelect"
                    label={t(REPORTS_LABELS.SELECT_PROJECTS)}
                    data={projects.map(p => ({ key: p.id, value: p.id, name: p.name }))}
                    values={selectedProjects}
                    onChange={handleProjectChange}
                    visible={currentReportDefinition?.fields.projectSelect.visible || false}
                    required={currentReportDefinition?.fields.projectSelect.required} />
                <SelectWithCheckboxes id="userSelect"
                    label={t(REPORTS_LABELS.SELECT_USERS)}
                    data={users.map(u => ({ key: u.id, value: u.id, name: u.displayName }))}
                    values={selectedUsers}
                    onChange={handleUserChange}
                    visible={currentReportDefinition?.fields.userSelect.visible || false}
                    required={currentReportDefinition?.fields.userSelect.required} />
                <SelectWithCheckboxes id="kindSelect"
                    label={t(REPORTS_LABELS.SELECT_KINDS)}
                    data={kinds.map(k => ({ key: k.id, value: k.id, name: k.name }))}
                    values={selectedKinds}
                    onChange={handleKindChange}
                    visible={currentReportDefinition?.fields.kindSelect.visible || false}
                    required={currentReportDefinition?.fields.kindSelect.required} />
                <SelectWithCheckboxes id="wbsSelect"
                    label={t(REPORTS_LABELS.SELECT_WBS)}
                    data={projects.filter(p => selectedProjects.includes(p.id))
                        .reduce((result: FormSelectData[], p) => result.concat(p.WBS.map(w => ({ key: w.id, value: w.id, name: `${w.code} - ${w.name}` }))), [])}
                    values={selectedWbs}
                    onChange={handleWbsChange}
                    visible={currentReportDefinition?.fields.wbsSelect.visible || false}
                    required={currentReportDefinition?.fields.wbsSelect.required} />
                <Button
                    type="submit"
                    variant="contained">
                    {t(REPORTS_LABELS.CREATE)}
                </Button>
            </Stack>
        </form>
    )
}