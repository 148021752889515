import { Box, CircularProgress } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { NoAccess, SideBarMenu } from '..'
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from '@azure/msal-react'
import { useEffect, useState } from 'react'
import { LayoutProps } from './types'
import { useSelector } from 'react-redux'
import { HATState } from '../../state'
import { LAYOUT_LABELS } from './translations'
import { useAppTranslationByLocation } from '../../translations'

export const Layout: React.FC<LayoutProps> = ({ userInfo }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const isVerificationFinished = useSelector((state: HATState) => state.notifications.userVerificationFinished)

    const { t } = useAppTranslationByLocation()

    useEffect(() => {
        isLoading && isVerificationFinished && setIsLoading(false) //if the user is already authorized
    }, [isLoading, userInfo, isVerificationFinished])

    return (
        <>
            <UnauthenticatedTemplate>
                <Box sx={{ display: 'flex', justifyContent: 'space-around', alignContent: 'center', pt: '100px' }}>
                    {t(LAYOUT_LABELS.LOGIN_IN_PROGRESS)}
                </Box>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                {isLoading ?
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', alignContent: 'center', pt: '100px' }}>
                        <CircularProgress />
                    </Box>
                    : userInfo.hasAccess ?
                        <>
                            <SideBarMenu role={userInfo.role} />
                            <Box
                                sx={{
                                    marginTop: "70px",
                                    display: "flex",
                                    flexGrow: 1,
                                    overflow: "auto"
                                }}
                            >
                                <Outlet />
                            </Box>
                        </>
                        :
                        <NoAccess />
                }
            </AuthenticatedTemplate>
        </>
    )
}