import { put, select, call } from 'redux-saga/effects'
import {
    deleteWbsAction,
    setUsersForWbsAction,
    setWbsForUserAction,
    updateWBSForProjectAction,
    usersForWbsSavedAction,
    wbsDeleteResultAction,
} from '../../redux/actions'
import { userInfoTokenSelector } from '../../selectors'
import { UserData, WBSData } from '../../types'
import { consoleLoggerServiceFactory, wbsService } from '../../../services'
import { DeleteWbsViaSagaAction, LoadUsersForWbsSagaAction, LoadWbsForUserViaSagaAction, SaveUsersForWbsViaSagaAction, SaveWBSViaSagaAction, SaveWbsForUserViaSagaAction } from './types'
import { ResponseStatus } from '../../../services/types'
import { loadWbsForUserViaSagaAction } from './actions'

export function* loadUsersForWbsViaSaga(action: LoadUsersForWbsSagaAction) {
    const { accessToken } = yield select(userInfoTokenSelector)
    const users: UserData[] = yield call(wbsService(accessToken).getUsersForWbs, action.payload.wbsId);

    consoleLoggerServiceFactory().logMessage("loadUsersForWbsViaSaga: ", users)
    yield put(setUsersForWbsAction({ users }))
}

export function* loadWbsForUserViaSaga(action: LoadWbsForUserViaSagaAction) {
    const { accessToken } = yield select(userInfoTokenSelector)
    const wbs: WBSData[] = yield call(wbsService(accessToken).getWbsForUser, action.payload.userId);

    consoleLoggerServiceFactory().logMessage("loadWbsForUserViaSaga: ", wbs)
    yield put(setWbsForUserAction({ wbs }))
}

//Handle both insert and update
export function* saveWBSViaSaga(action: SaveWBSViaSagaAction) {
    const { accessToken } = yield select(userInfoTokenSelector)
    var result: WBSData

    if (action.payload.id === 0) {
        result = yield call(wbsService(accessToken).createWBS, action.payload)
    } else {
        result = yield call(wbsService(accessToken).updateWBS, action.payload)
    }

    yield put(updateWBSForProjectAction({ wbs: result }))
}

export function* deleteWbsViaSaga(action: DeleteWbsViaSagaAction) {
    const { accessToken } = yield select(userInfoTokenSelector)

    const result: ResponseStatus = yield call(wbsService(accessToken).deleteWBS, action.payload.id);

    if (result === ResponseStatus.OK) {
        yield put(deleteWbsAction({ id: action.payload.id }))
    }
    yield put(wbsDeleteResultAction(result))
}

export function* saveUsersForWbsViaSaga(action: SaveUsersForWbsViaSagaAction) {
    const { accessToken } = yield select(userInfoTokenSelector)

    const { userData, responseStatus } = yield call(wbsService(accessToken).saveUsersForWbs, action.payload.wbsId, action.payload.userIds);

    yield put(setUsersForWbsAction({ users: userData }))
    yield put(usersForWbsSavedAction(responseStatus))
}

export function* saveWbsForUserViaSaga(action: SaveWbsForUserViaSagaAction) {
    const { accessToken } = yield select(userInfoTokenSelector)
    yield call(wbsService(accessToken).saveWbsForUser, action.payload.userId, action.payload.wbsIds);

    consoleLoggerServiceFactory().logMessage("saveWbsForUserViaSaga: ", action.payload)
    yield put(loadWbsForUserViaSagaAction({ userId: action.payload.userId }));
}