import { Typography, Box, Link } from "@mui/material"
import { NO_ACCESS_LABELS } from './translations';
import { useAppTranslationByLocation } from "../../translations";

export const NoAccess = () => {
    const { t } = useAppTranslationByLocation();

    return (
        <Box p={4} textAlign={"center"}>
            <Typography variant="h4">{t(NO_ACCESS_LABELS.HEADER)}</Typography>
            <Typography pt={2}>
                {t(NO_ACCESS_LABELS.MESSAGE)}
                &nbsp;
                <Link href="mailto:sms.ba.services@accenture.com">sms.ba.services@accenture.com</Link>.
            </Typography>
        </Box>
    )
}