
import { call, takeLatest } from 'redux-saga/effects'

import * as Constants from './constants/index'
import * as Types from './types'

import {
    loadDayDataSaga,
    loadMonthRegistrationsSaga,
    saveDayDataSaga,
    deleteRegistrationSaga,
    approveHoursViaSaga,
    createRecurrentRegistrationViaSaga,
    saveUserRegistrationsViaSaga,
    loadUserMonthRegistrationsViaSaga,
    deleteUserRegistrationViaSaga,
    createUserRecurrentRegistrationViaSaga,
} from './hoursSagas'

import {
    loadKindsSaga,
} from './kindSagas'

import {
    validateCurrentUserSaga,
    loadAllUsersViaSaga,
    updateUserSaga,
    createUserViaSaga,
    saveDarkModeViaSaga,
    loadCurrentUserSettingsViaSaga,
} from './userSagas'

import {
    loadCurrentUserProjectsViaSaga,
    loadAllProjectsDataSaga,
    createOrUpdateProjectSaga,
    loadUserProjectsViaSaga,
} from './projectSagas'

import {
    saveWBSViaSaga,
    deleteWbsViaSaga,
    saveUsersForWbsViaSaga,
    saveWbsForUserViaSaga,
    loadUsersForWbsViaSaga,
    loadWbsForUserViaSaga,
} from './wbsSagas'
import { tokenRenewalSaga, userLoginSaga } from './authenticationSagas'

export function* hatSagas() {
    yield takeLatest<Types.LoadDayDataViaSagaAction>(Constants.LOAD_DAY_DATA_SAGA, loadDayDataSaga)
    yield takeLatest<Types.LoadMonthRegistrationsViaSagaAction>(Constants.LOAD_MONTH_REGISTRATIONS_SAGA, loadMonthRegistrationsSaga)
    yield takeLatest<Types.LoadKindDataViaSagaAction>(Constants.LOAD_KIND_DATA_SAGA, loadKindsSaga)
    yield takeLatest<Types.SaveDayDataViaSagaAction>(Constants.SAVE_DAY_DATA_SAGA, saveDayDataSaga)
    yield takeLatest<Types.DeleteRegistrationViaSagaAction>(Constants.DELETE_REGISTRATION_SAGA, deleteRegistrationSaga)
    yield takeLatest<Types.LoadAllProjectDataViaSagaAction>(Constants.LOAD_ALL_PROJECTS_DATA_SAGA, loadAllProjectsDataSaga)
    yield takeLatest<Types.UpdateUserViaSagaAction>(Constants.UPDATE_USER_SAGA, updateUserSaga)
    yield takeLatest<Types.CreateOrUpdateProjectViaSagaAction>(Constants.CREATE_OR_UPDATE_PROJECT_SAGA, createOrUpdateProjectSaga)
    yield takeLatest<Types.SaveWBSViaSagaAction>(Constants.SAVE_WBS_SAGA, saveWBSViaSaga)
    yield takeLatest<Types.LoadAllUsersViaSagaAction>(Constants.LOAD_ALL_USERS_SAGA, loadAllUsersViaSaga)
    yield takeLatest<Types.ApproveHoursViaSagaAction>(Constants.APPROVE_HOURS_SAGA, approveHoursViaSaga)
    yield takeLatest<Types.LoadUserMonthRegistrationsViaSagaAction>(Constants.LOAD_USER_MONTH_REGISTRATIONS_SAGA, loadUserMonthRegistrationsViaSaga)
    yield takeLatest<Types.LoadUserProjectsViaSagaAction>(Constants.LOAD_USER_PROJECTS_SAGA, loadUserProjectsViaSaga)
    yield takeLatest<Types.LoadCurrentUserProjectsViaSagaAction>(Constants.LOAD_CURRENT_USER_PROJECTS_SAGA, loadCurrentUserProjectsViaSaga)
    yield takeLatest<Types.SaveUserRegistrationsViaSagaAction>(Constants.SAVE_USER_REGISTRATIONS_SAGA, saveUserRegistrationsViaSaga)
    yield takeLatest<Types.DeleteUserRegistrationViaSagaAction>(Constants.DELETE_USER_REGISTRATION_SAGA, deleteUserRegistrationViaSaga)
    yield takeLatest<Types.ValidateCurrentUserViaSagaAction>(Constants.VALIDATE_CURRENT_USER_SAGA, validateCurrentUserSaga)
    yield takeLatest<Types.CreateRecurrentRegistrationViaSagaAction>(Constants.CREATE_RECURRENT_REGISTRATION_SAGA, createRecurrentRegistrationViaSaga)
    yield takeLatest<Types.DeleteWbsViaSagaAction>(Constants.DELETE_WBS_SAGA, deleteWbsViaSaga)
    yield takeLatest<Types.CreateUserRecurrentRegistrationViaSagaAction>(Constants.CREATE_USER_RECURRENT_REGISTRATION_SAGA, createUserRecurrentRegistrationViaSaga)
    yield takeLatest<Types.LoadUsersForWbsSagaAction>(Constants.LOAD_USERS_FOR_WBS_SAGA, loadUsersForWbsViaSaga)
    yield takeLatest<Types.SaveUsersForWbsViaSagaAction>(Constants.SAVE_USERS_FOR_WBS_SAGA, saveUsersForWbsViaSaga)
    yield takeLatest<Types.CreateUserViaSagaAction>(Constants.CREATE_USER_SAGA, createUserViaSaga)
    yield takeLatest<Types.LoadWbsForUserViaSagaAction>(Constants.LOAD_WBS_FOR_USER_SAGA, loadWbsForUserViaSaga)
    yield takeLatest<Types.SaveWbsForUserViaSagaAction>(Constants.SAVE_WBS_FOR_USER_SAGA, saveWbsForUserViaSaga)
    yield takeLatest<Types.LoadCurrentUserSettingsSagaAction>(Constants.LOAD_CURRENT_USER_SETTINGS_SAGA, loadCurrentUserSettingsViaSaga)
    yield takeLatest<Types.SaveDarkModeSettingsViaSagaAction>(Constants.SAVE_DARK_MODE_SETTINGS_SAGA, saveDarkModeViaSaga)
    yield takeLatest<Types.UserLoginViaSagaAction>(Constants.USER_LOGIN_SAGA, userLoginSaga);

    yield call(tokenRenewalSaga);
}
