export type RegistrationData = {
    id: number;
    projectId: number;
    projectName: string;
    date: Date;
    wbsId: number;
    wbsName: string;
    wbsCode: string;
    ticket: string;
    activity: string;
    hours: number;
    typeId: number;
    typeName: string;
    userId: number;
    isApproved: boolean;
};

export type KindData = {
    id: number;
    name: string;
};

export type WBSData = {
    id: number;
    name: string;
    code: string;
    active: boolean;
    projectId: number;
};

export type ProjectData = {
    id: number;
    name: string;
    active: boolean;
    WBS: WBSData[];
};

export type UserData = {
    id: number;
    userName: string;
    displayName: string;
    isActive: boolean;
    email: string;
};

export type UserSettings = {
    darkMode: boolean;
}

/**
 * This is information about the user logged into the application
 */
export type UserInfo = {
    fullName: string;
    email: string;
    role: string;
    hasAccess: boolean;   //result of database validation
};

export type TokenInfo = {
    accessToken: string,
    expiresOn: Date,
}

export type UserPreferences = {
    darkMode: boolean;
};

export enum RecurrencePatternFE {
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
}

export type RecurrenceInfo = {
    pattern: RecurrencePatternFE,
    endDate: Date
}
