import { all, call, put, select } from 'redux-saga/effects'
//import { logErrorMessage, logInfoMessage } from '../../redux/actions'
import { ApproveHoursViaSagaAction, CreateRecurrentRegistrationViaSagaAction, CreateUserRecurrentRegistrationViaSagaAction, DeleteRegistrationViaSagaAction, DeleteUserRegistrationViaSagaAction, LoadDayDataViaSagaAction, LoadMonthRegistrationsViaSagaAction, LoadUserMonthRegistrationsViaSagaAction, SaveDayDataViaSagaAction, SaveUserRegistrationsViaSagaAction } from './types'
import {
    setRegistrationsAction,
    registrationsLoadingFinishedAction,
    deleteRegistrationAction,
    updateRegistrationsAction,
    updateApprovedStatus,
    updateUserRegistrations,
    setUserRegistrationsAction,
    deleteUserRegistration,
} from '../../redux/actions'
import { userInfoTokenSelector } from '../../selectors'
import { RegistrationData } from '../../types'
import { hoursService, impersonationService } from '../../../services'
import { firstDayOfMonth, lastDayOfMonth } from '../../../utils'
import { consoleLoggerServiceFactory } from '../../../services'

export function* loadDayDataSaga(action: LoadDayDataViaSagaAction) {
    const { accessToken } = yield select(userInfoTokenSelector)

    const data: RegistrationData[] = yield call(
        hoursService(accessToken).getHoursOfUserBetweenDates,
        action.payload.date,
        action.payload.date
    )
    consoleLoggerServiceFactory().logMessage("loadDayDataSaga: ", data)

    yield put(setRegistrationsAction(data));
    yield put(registrationsLoadingFinishedAction(true));
}

export function* loadMonthRegistrationsSaga(action: LoadMonthRegistrationsViaSagaAction) {
    const { accessToken } = yield select(userInfoTokenSelector)
    const dateFrom = firstDayOfMonth(action.payload.dateInPeriod)
    const dateTo = lastDayOfMonth(action.payload.dateInPeriod)

    const data: RegistrationData[] = yield call(
        hoursService(accessToken).getHoursOfUserBetweenDates,
        dateFrom,
        dateTo
    )
    consoleLoggerServiceFactory().logMessage("loadMonthRegistrationsSaga: ", data)

    yield put(setRegistrationsAction(data))
    yield put(registrationsLoadingFinishedAction(true))
}

export function* saveDayDataSaga(action: SaveDayDataViaSagaAction) {
    consoleLoggerServiceFactory().logMessage("saveDayDataSaga: ", action);
    const { accessToken } = yield select(userInfoTokenSelector)
    var result: RegistrationData[] = yield call(hoursService(accessToken).createOrUpdateHours, action.payload.registrations);

    //now remove all the records with temporary id from state
    yield all(action.payload.registrations.filter(r => r.id < 0).map(r =>
        put(deleteRegistrationAction(r.id)))
    )

    //and update the status with fresh registrations
    yield put(updateRegistrationsAction(result))
    yield put(registrationsLoadingFinishedAction(true))
}

export function* deleteRegistrationSaga(action: DeleteRegistrationViaSagaAction) {
    const registrationId = action.payload.id;

    //if id < 0 then the registration is not saved in backend yet. No need to call backend delete operation: a
    if (registrationId > 0) {
        const { accessToken } = yield select(userInfoTokenSelector)
        yield call(hoursService(accessToken).deleteHours, { id: registrationId });
    }
    yield put(deleteRegistrationAction(registrationId));
    yield put(registrationsLoadingFinishedAction(true))
}

export function* approveHoursViaSaga(action: ApproveHoursViaSagaAction) {
    const { accessToken } = yield select(userInfoTokenSelector)
    yield call(hoursService(accessToken).approveHours, action.payload)

    yield put(updateApprovedStatus(action.payload))
}

export function* createRecurrentRegistrationViaSaga(action: CreateRecurrentRegistrationViaSagaAction) {
    const { accessToken } = yield select(userInfoTokenSelector)
    const result: RegistrationData[] = yield call(hoursService(accessToken).createRecurrentHours, action.payload.registration, action.payload.recurrence)

    yield put(updateRegistrationsAction(result))
    yield put(registrationsLoadingFinishedAction(true))
}

//---- Impersonation ----

export function* saveUserRegistrationsViaSaga(action: SaveUserRegistrationsViaSagaAction) {
    const { accessToken } = yield select(userInfoTokenSelector)
    const result: RegistrationData[] = yield call(impersonationService(accessToken).updateHoursForUser, action.payload.registrations)

    yield put(updateUserRegistrations({ registrations: result }))
    yield put(registrationsLoadingFinishedAction(true))
}

export function* loadUserMonthRegistrationsViaSaga(action: LoadUserMonthRegistrationsViaSagaAction) {
    const { accessToken } = yield select(userInfoTokenSelector)
    const dateFrom = firstDayOfMonth(action.payload.dateInPeriod)
    const dateTo = lastDayOfMonth(action.payload.dateInPeriod)

    const registrations: RegistrationData[] = yield call(
        impersonationService(accessToken).getHoursOfUsersBetweenDates,
        action.payload.userId, dateFrom, dateTo
    );

    consoleLoggerServiceFactory().logMessage("loadUserMonthRegistrationsViaSaga: ", registrations)
    yield put(setUserRegistrationsAction({ registrations }));
    yield put(registrationsLoadingFinishedAction(true))
}

export function* deleteUserRegistrationViaSaga(action: DeleteUserRegistrationViaSagaAction) {
    const { accessToken } = yield select(userInfoTokenSelector)
    yield call(impersonationService(accessToken).deleteChargedHour, action.payload.id);

    yield put(deleteUserRegistration(action.payload));
    yield put(registrationsLoadingFinishedAction(true))
}

export function* createUserRecurrentRegistrationViaSaga(action: CreateUserRecurrentRegistrationViaSagaAction) {
    const { accessToken } = yield select(userInfoTokenSelector)
    const result: RegistrationData[] = yield call(impersonationService(accessToken).createRecurrentHours, action.payload.registration, action.payload.recurrence)

    yield put(updateUserRegistrations({ registrations: result }))
    yield put(registrationsLoadingFinishedAction(true))
}