import * as Constants from "../../actions/userInfo/constants"
import { UserInfoActions } from "../../actions/userInfo/types"
import { TokenInfo, UserInfo } from "../../../types"

type UserState = {
    userInfo: UserInfo,
    tokenInfo: TokenInfo,
}

const emptyUser: UserInfo = {
    fullName: "Unknown User",
    email: "no email",
    role: "",
    hasAccess: true,       //TODO: check what value is better as default
}

const emptyToken: TokenInfo = {
    accessToken: "",
    expiresOn: new Date(),
}

export const userInfoReducer = (
    state: UserState = { userInfo: emptyUser, tokenInfo: emptyToken },
    action: UserInfoActions
) => {
    switch (action.type) {
        case Constants.SET_USER_INFO: {
            return { ...state, userInfo: action.payload.userInfo }
        }
        case Constants.SET_USER_VALIDATION_RESULT: {
            return { ...state, userInfo: { ...state.userInfo, hasAccess: action.payload.hasAccess } }
        }
        case Constants.SET_TOKEN_INFO: {
            return { ...state, tokenInfo: action.payload.tokenInfo }
        }

        default: {
            return state
        }
    }
}