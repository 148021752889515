import { HATState } from "../..";
import { TokenInfo, UserInfo } from "../../types";

export const userInfoTokenSelector = (state: HATState): TokenInfo => {
    return state.userInfo.tokenInfo
}

export const userInfoUserNameSelector = (state: HATState): string => {
    return state.userInfo.userInfo.fullName
}

export const userInfoRolesSelector = (state: HATState): string => {
    return state.userInfo.userInfo.role
}

export const userInfoSelector = (state: HATState): UserInfo => {
    return state.userInfo.userInfo
}