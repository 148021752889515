import { FC, useEffect } from 'react';
import { AppRoutes } from './routes';
import { AppProps } from './types';
import { ThemeProvider } from '@mui/material';
import { hatDarkTheme, hatLightTheme } from './utils/hatTheme';
import { useDispatch, useSelector } from 'react-redux';
import { HATState } from './state';
import { isUserLoggedIn } from './authentication/msalConfiguration';
import { userLoginViaSagaAction } from './state/sagas/actions';

export const App: FC<AppProps> = (props: AppProps) => {
  const isDarkMode = useSelector((state: HATState) => state.settings.darkMode);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isUserLoggedIn()) {
      dispatch(userLoginViaSagaAction());
    }
  }, [dispatch]);

  return (
    <div className="App" style={{ height: '100vh', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
      <ThemeProvider theme={isDarkMode ? hatDarkTheme : hatLightTheme}>
        <AppRoutes />
      </ThemeProvider>
    </div>
  );
};
