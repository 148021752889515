import { DateSelect } from '../../components/formElements';
import { PeriodSelectProps } from "./types"
import { Stack, Button } from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { isInCurrentPeriod } from '../../utils';

export const PeriodSelect: React.FC<PeriodSelectProps> = (
    { selectedDate, onDateChanged }) => {

    const onChangePreviousPeriod = () => {
        const { previousDate } = findClosestPeriodStart(selectedDate)
        onDateChanged(isInCurrentPeriod(previousDate) ? new Date() : previousDate)
    }

    const onChangeNextPeriod = () => {
        const { nextDate } = findClosestPeriodStart(selectedDate)
        onDateChanged(isInCurrentPeriod(nextDate) ? new Date() : nextDate)
    }

    const findClosestPeriodStart = (currentDate: Date): { previousDate: Date, nextDate: Date } => {
        const previousDate = new Date(currentDate)
        const nextDate = new Date(currentDate)
        if (currentDate.getDate() <= 15) {
            previousDate.setDate(16)
            previousDate.setMonth(currentDate.getMonth() - 1)
            nextDate.setDate(16)
            nextDate.setMonth(currentDate.getMonth())
        }
        else {
            previousDate.setDate(1)
            previousDate.setMonth(currentDate.getMonth())
            nextDate.setDate(1)
            nextDate.setMonth(currentDate.getMonth() + 1)
        }
        return { previousDate, nextDate }
    }

    return (
        <Stack direction={'row'} spacing={1} justifyContent={'flex-start'} >
            <Button
                aria-label='previous-period'
                color='primary'
                variant='outlined'
                onClick={onChangePreviousPeriod}
            >
                <ChevronLeft />
            </Button>
            <DateSelect
                selectedDate={selectedDate}
                onDateChanged={onDateChanged}
            />
            <Button
                aria-label='next-period'
                color='primary'
                variant='outlined'
                onClick={onChangeNextPeriod}
            >
                <ChevronRight />
            </Button>
        </Stack >
    );
}


